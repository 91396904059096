import {
    MainConfig
} from "../../types";

import { redirect } from "../../utils";

export const config : MainConfig = {
    language: "es",
    chat: {
        botName: "Emily",
        botAvatar: "./img/avatar.webp",
        userAvatar: "./img/user.png",
        typingDelay: 500,
        sections: [{
                page_name: "find_out_if_you_qualify",
                messages: [{
                        text: `Hola ${String.fromCodePoint(128079)}`,
                        delay: 1000,
                    },
                    {
                        text: "Soy Emily de Health Benefits, su asistente virtual de AI",
                        delay: 1000,
                    },
                    {
                        text: `¿Quieres saber si tú o tu familia califican para el plan de salud? ¡Toca Sí si es así!  ${String.fromCodePoint(128512)}`,
                        delay: 1000,
                    },
                    {
                        text: null,
                        delay: 0,
                        selections: [{
                            questionKey: "opt_in",
                            title: "Sí",
                            value: true,
                            nextSectionIndex: 1
                        }]
                    }
                ],
            },
            {
                page_name: "income_less_than_60k",
                messages: [{
                        text: "Bien, déjame hacerte un par de preguntas rápidas.",
                        delay: 1000
                    },
                    {
                        text: "¿Ganas menos de $60,000 al año? Toca Sí o No?",
                        delay: 1000
                    },
                    {
                        delay: 0,
                        selections: [{
                                questionKey: "income_less_than_60k",
                                title: "Sí",
                                value: true,
                                nextSectionIndex: 2
                            },
                            {
                                questionKey: "income_less_than_60k",
                                title: "No",
                                value: false,
                                nextSectionIndex: 2
                            }
                        ]
                    }
                ],
    
            },
            {
                page_name: "over_or_under_65",
                messages: [{
                        text: "¿Tienes más o menos de 65 años?",
                        delay: 1000
                    },
                    {
                        delay: 0,
                        selections: [{
                                questionKey: "age",
                                title: "Más",
                                value: false,
                                nextSectionIndex: 4
                            },
                            {
                                questionKey: "age",
                                title: "Menos",
                                value: "Medicaid",
                                nextSectionIndex: 3
                            },
                        ]
                    }
                ],
            },
            {
                page_name: "medicare_or_medicaid",
                messages: [{
                        text: "¿Estás en Medicaid o Medicare?",
                        delay: 1000
                    },
                    {
                        delay: 0,
                        selections: [{
                                questionKey: "medicaid_or_medicare",
                                title: "Medicare",
                                value: "Medicare",
                                nextSectionIndex: 4
                            },{
                                questionKey: "medicaid_or_medicare",
                                title: "Medicaid",
                                value: "Medicaid",
                                nextSectionIndex: 9
                            },{
                                questionKey: "medicaid_or_medicare",
                                title: "No",
                                value: false,
                                nextSectionIndex: 5
                            },
                   
                        ]
                    }
                ],
            },
            {
                page_name: "medicare_a_b",
                messages: [{
                        text: "Do you have Medicare parts A and B?",
                        delay: 1000
                    },
                    {
                        delay: 0,
                        selections: [{
                                questionKey: "medicare_a_b",
                                title: "Yes",
                                value: "Yes",
                                nextSectionIndex: 6
                            },{
                                questionKey: "medicare_a_b",
                                title: "No",
                                value: false,
                                nextSectionIndex: 7
                            },
                   
                        ]
                    }
                ],
            },
            {
                page_name: "td_aca",
                messages: [
                    {
                        text: `${String.fromCodePoint(127881)} ¡Felicidades! ${String.fromCodePoint(127873)}`,
                        delay: 1000,
                    },
                    {
                        text: "Tú o tu familia pueden calificar para un Plan de Salud de $0",
                        delay: 1000
                    },
                    {
                        text: "Toca el botón de número abajo para llamar ahora y ver si calificas, la llamada inicial solo toma unos minutos.",
                        delay: 1000
                    },
                    {
                        delay: 1000,
                        did: {
                            tracker: 'trackdrive'
                        }
                    },
                    {
                        text: "TTY: 711",
                        delay: 500
                    },
                    {
                        text: "Horario de Oficina: Lunes a Viernes 9am-6pm ET",
                        delay: 500
                    }
                ],
            },
            {
                page_name: "retreaver_medi",
                messages: [
                    {
                        text: `¡Buenas Noticias! ${String.fromCodePoint(127881)}`,
                        delay: 1000,
                    },
                    {
                        text: "Aunque no califica para un Plan de Salud de $0 y una Tarjeta de Gastos de $500 a través del Seguro ACA, puede ser elegible para un Plan Medicare Advantage con beneficios adicionales [si tiene un Período de Inscripción Especial disponible. Por ejemplo, si se mudó recientemente, está en el programa Medicaid de su estado, o tiene otros eventos calificativos].",
                        delay: 1000
                    },
                    {
                        text: "Llame al número a continuación para comunicarse con un agente de ventas autorizado para verificar su elegibilidad para inscribirse y obtener más información sobre estas opciones de planes:",
                        delay: 1000
                    },
                    {
                        delay: 0,
                        did: {
                            tracker: "retreaver"
                        }
                    },
                    {
                        text: "TTY: 711",
                        delay: 500
                    },
                    {
                        text: "Horario de Oficina: Lunes a Viernes 9am-6pm ET",
                        delay: 500
                    },
                ],
            },
            {
                page_name: "acp",
                messages: [
                    {
                        text: `¡Buenas Noticias! ${String.fromCodePoint(127881)}`,
                        delay: 1000,
                    },
                    {
                        text: "Aunque no calificas para un Subsidio de Salud, las agencias ahora están ofreciendo planes celulares y teléfonos gratuitos.",
                        delay: 1000
                    },
                    {
                        text: "¡Haz clic en el enlace a continuación para asegurar tu teléfono y plan de datos celular gratuito ahora!",
                        delay: 1000
                    },
                    {
                        delay: 0,
                        selections: [{
                            title: "¡Obtener mi teléfono gratis!",
                            onClick: () => redirect('https://273externalid.mvnocloudsolutions.com/?AccountID=20')
                        }
                    ]
                    }
                ],
            },
            {
                page_name: "DQ",
                messages: [
                    {
                        text: "Lo sentimos, pero las preguntas que respondiste nos han ayudado a determinar que no podemos ayudarte en este momento.",
                        delay: 1500
                    },
                    {
                        text: `¡Que tengas un buen día! ${String.fromCodePoint(128512)}`,
                        delay: 1500
                    },
                ],
            },
            {
                page_name: "credit_repair",
                messages: [
                    {
                        text: `¡Buenas Noticias! ${String.fromCodePoint(127881)}`,
                        delay: 1000,
                    },
                    {
                        text: "Aunque no calificas para el subsidio de seguro de salud, este nuevo programa puede permitirte aumentar tu puntaje de crédito rápidamente.",
                        delay: 1000
                    },
                    {
                        delay: 0,
                        selections: [{
                            title: "¡Aumentar mi puntaje de crédito!",
                            onClick: () => redirect('https://www.frebahlem.com/BGXRTCHP/HT1C69P/')
                        }
                    ]
                    }
                ],
            },
        ]
    },
    header: {
        headline: "Obtenga un Plan de Salud de $0 con otros beneficios"
    },
    disclosures: {
        "paragraphs": [
            "MULTI-PLAN_5imgij6_2024_C MULTI-PLAN_5imgij6_2025_C",
            "Las agencias de ventas participantes representan organizaciones de Medicare Advantage HMO, PPO y PFFS y planes de medicamentos recetados PDP independientes que tienen contrato con Medicare. La inscripción depende de la renovación del contrato del plan. La inscripción en el tipo de plan descrito puede estar limitada a ciertos momentos del año a menos que califique para un Período de Inscripción Especial.",
            "Este sitio web no está afiliado al programa de la Ley del Cuidado de Salud a Bajo Precio ni a ninguna otra entidad gubernamental. La información proporcionada en este sitio web es solo para fines informativos. No pretende ser, ni constituye ningún tipo de asesoramiento financiero. Por favor, busque asesoramiento de un profesional calificado antes de tomar cualquier decisión financiera basada en la información proporcionada. Este sitio web actúa como un editor independiente de medios digitales y publicidad. Esta página web está formateada como un publirreportaje. Un publirreportaje es un anuncio escrito en formato de noticia editorial. POR FAVOR, TENGA EN CUENTA QUE ESTO ES UN ANUNCIO Y NO UN ARTÍCULO DE NOTICIAS REAL, UN BLOG O UNA ACTUALIZACIÓN DE PROTECCIÓN AL CONSUMIDOR. Este sitio web PUEDE RECIBIR COMPENSACIÓN PAGADA POR CLICS O VENTAS PRODUCIDAS A PARTIR DEL CONTENIDO ENCONTRADO EN ESTA PÁGINA WEB. Cualquier información, descuento o cotización de precios listada puede no ser aplicable en su ubicación o si no se cumplen ciertos requisitos. Además, nuestros anunciantes pueden tener requisitos de calificación adicionales.",
            "Nuestro objetivo es proporcionar un servicio excepcional. Uno de nuestros agentes puede comunicarse con usted para discutir su pedido, solicitar comentarios y/o ver si necesita ayuda con sus productos, servicios o planes, al número de teléfono que proporcionó, independientemente de su estado en la lista de no llamar. Puede optar por no recibir más contactos en cualquier momento simplemente diciéndole a nuestro equipo de servicio al cliente que ya no desea ser contactado. En caso de que nuestro equipo no pueda comunicarse con usted por teléfono, pueden enviarle un mensaje de texto informándole que llamamos. Tanto nuestros mensajes de texto como las llamadas telefónicas pueden ser enviados o conectados utilizando software automatizado. Pueden aplicarse cargos de operador. Puede optar por no recibir ningún contacto futuro a través de mensajes de texto respondiendo en cualquier momento con \"STOP\"."
        ],
        "information_links": [
            {"title": "ACHI 2022", "href": "https://achi.net/newsroom/house-passes-bill-extending-aca-subsidies-through-2025-ensuring-continued-affordable-healthcare-insurance-for-millions/?"},
            {"title": "KFF 2022", "href": "https://www.kff.org/faqs/faqs-health-insurance-marketplace-and-the-aca/how-do-the-premium-tax-credits-work/"},
            {"title": "IRS 2022", "href": "https://www.irs.gov/affordable-care-act/individuals-and-families/the-premium-tax-credit-the-basics"},
            {"title": "Health Reform Basics 2022", "href": "https://www.healthreformbeyondthebasics.org/premium-tax-credits-answers-to-frequently-asked-questions/"},
            {"title": "CMS.gov", "href": "https://cms.gov"}
        ],
        "terms_links": [
            {"title": "Privacy Policy", "href": "https://chat.healthbenefitsnow.org/privacy_policy/index.html" },
            {"title": "Terms and Conditions", "href": "https://chat.healthbenefitsnow.org/terms_of_service/index.html"}
        ]
    },
    numberPools: {
        retreaver: {
            campaign_key: "bd376b8fa8c3c1efae7dfc8e19bb179e"
        },
        retreaverACA: {
            campaign_key: "249731b64ba9c84240b7934560eba899"
        },
        trackdrive: {
            offer_token: "754a9936801e8ff256925b58a748521e",
            default_number: "+18449340565",
            default_human_number: "(844) 934 0565"
        }
    }
}