import React from "react";
import { config } from "../../config/main";
import s from './style.module.scss'

const Disclosures = () =>{

    const linkClick = (e: any, href: string) => {
        e.preventDefault();
        window.open(href)
    }

    return(
        <div className={s.container}>
            {config.disclosures.paragraphs.map((p, i)=><p key={i} className={s.paragraph}>{p}</p>)}
            <div className={s.informationLinks}>
                {config.disclosures.information_links.map((link, i)=>{
                    return i !== config.disclosures.information_links.length -1 ? (<span key={i} className={s.divider}><a className={s.link} href={link.href}>{link.title}</a> | </span>) : <a key={i} className={s.link} href={link.href}>{link.title}</a>
                })}
            </div>
            <div className={s.termsLinks}>
            {config.disclosures.terms_links.map((link, i)=>{
                    return i !== config.disclosures.terms_links.length -1 ? (<span key={i} className={s.divider}><a className={s.link} href={link.href} onClick={(e)=>linkClick(e, link.href)}>{link.title}</a> | </span>) : <a key={i} className={s.link} href={link.href}>{link.title}</a>
                })}
            </div>
        </div>
    )
}

export default Disclosures;