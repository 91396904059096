import {config as chat} from './variants/default';
import {config as card} from './variants/card';
import {config as cardB} from './variants/cardB'
import {config as cardC} from './variants/cardC';
import {config as cardD} from './variants/cardD';
import {config as a} from './variants/a';
import {config as b} from './variants/b';
import {config as c} from './variants/c';
import {config as d} from './variants/d';
import {config as b2} from './variants/b2';
import {config as b3} from './variants/b3';
import {config as s} from './variants/s';
import {config as fl} from './variants/fl';
import {config as b_sky} from './variants/b_sky';


//make sure you add your new configs in this file

const configFile = () => { switch(process.env.REACT_APP_CONFIG_FILE){
    case "chat": return chat;
    case "card": return card;
    case "cardB": return cardB;
    case "cardC": return cardC;
    case "cardD": return cardD;
    case "a": return a;
    case "b": return b;
    case "c": return c;
    case "d": return d;
    case "b2": return b2;
    case "b3": return b3;
    case "s": return s;
    case "fl": return fl;
    case "b_sky": return b_sky;
    default: return chat;
}}

const config = configFile();

export { config }