import React from "react";
import { config } from "../../config/main";
import s from './style.module.scss'

const HeaderSpanish = () => {
    return(
        <div className={s.container}>
            <h3>Las Oficinas Cierran Hoy a Las 6:00 PM EST </h3>
            <h1>{config.header.headline}</h1>
            <div className={s.online}>
                <p className={s.pulsatingCircle}></p><p className={s.botName}> {config.chat.botName} Está en linea.</p>
            </div>
   
        </div>
    )

}

export default HeaderSpanish;