import React, {useState, useEffect, useRef, ReactEventHandler, SyntheticEvent} from "react";
import s from './style.module.scss'
import { config } from "../../config/variants/default";
import { SelectionButton } from "../../types";

const ChatMessage = (props: any) =>{

    const [loading, setLoading] = useState(props.delay > 0 ? true : false);
    const [trackDriveNumberReplaced, setTrackDriveNumberReplaced] = useState(false);
    const ref = useRef<null | HTMLDivElement>(null);
    const [conversionFired, setConversionFired] = useState(false);

    useEffect(()=>{
        setTimeout(()=>{
            setLoading(false)
        }, props.delay)
    },[])

    const onCall = (tracker: string) =>{
        if(!conversionFired){
            (window as any).gtag('event', determineConversionEvent(props.did.tracker));
            setConversionFired(true);
        }
        setTimeout(()=>{
            window.location.href = "https://offers.healthbenefitsnow.org";
        },15000)
    }

    const determineConversionEvent = (tracker: string) =>{
        switch(tracker){
            case "retreaver":
                return "medicare";
            case "retreaverACA":
                return "over_65_aca";
            case "trackdrive":
                return "aca";
        }
    }

    const determineNumber = (tracker: string) =>{
        switch(tracker){
            case "retreaver":
                return props.retreaverNumber;
            case "retreaverACA":
                return props.retreaverACANumber;
            case "trackdrive":
                return config.numberPools.trackdrive.default_number;
            default: 
                return config.numberPools.trackdrive.default_number;
        }
    }

    const determineHumanNumber = (tracker: string) =>{
        switch(tracker){
            case "retreaver":
                console.log("retreaverMedi");
                return props.retreaverHumanNumber;
            case "retreaverACA":
                console.log("retraverACA")
                return props.retreaverACAHumanNumber;
            case "trackdrive":
                console.log("TD");
                return config.numberPools.trackdrive.default_human_number;
            default: 
                return config.numberPools.trackdrive.default_human_number;
        }
    }

    useEffect(()=>{
        if(ref.current){
            window.scrollTo({top: ref.current.offsetTop - (window.innerHeight - ref.current.scrollHeight) +40})
        }
    })

    useEffect(()=>{
        if(props.did){
            if(props.did.tracker === "trackdrive" && !trackDriveNumberReplaced){
                let query = window.location.href.split('?');
                let tokens : any = {call_type: "lander"}
                if(query.length > 1){
                    let queryParams = new URLSearchParams(query[1]);
                    let RTClickId = queryParams.get("redtrack_click_id")
                    if(RTClickId)
                    tokens.redtrack_click_id = RTClickId
                    else
                    tokens.redtrack_click_id = (window as any).rtkClickID
                }else{
                    tokens.redtrack_click_id = (window as any).rtkClickID
                }
                (window as any).Trackdrive.Optimizer.replace_all({offer_token: config.numberPools.trackdrive.offer_token, tokens: tokens}, [
                    [config.numberPools.trackdrive.default_human_number, 'human_number'],
                    [config.numberPools.trackdrive.default_number, 'plain_number']
                ]);
                setTrackDriveNumberReplaced(true);
            }
        }
    }, [])


    const onClick = (selection: SelectionButton)=>{
        if(selection.nextSectionIndex)
            return props.onSelection(selection.questionKey, selection.value, selection.nextSectionIndex, selection.title)

        if(selection.onClick){
            (window as any).gtag('event', "acp_redirect");
            selection.onClick()
        }

           
    }

    if(!props.isUser){
        return(
            <div className={s.container} ref={ref}>
                <div className={s.avatarContainer}>
                    {props.showAvatar && (
                        <img className={s.avatarImage} src={config.chat.botAvatar} />
                    )}
                </div>
                <div className={s.messageContainer}>
                    <div>{
                        loading ? (  
                            <div className={s.typing}>
                                <div className={s.typing_dot}></div>
                                <div className={s.typing_dot}></div>
                                <div className={s.typing_dot}></div>
                            </div>
                        ) : <p>{props.text}</p>} 
                    </div>
                    <div className={props?.selections?.length > 2 ? s.column : undefined}>
                        {!loading && props.selections && props.selections.map((selection: SelectionButton, i: number)=><button key={i} className={s.button} onClick={()=>onClick(selection)}>{selection.title}</button>)}
                    </div>
                    <div >
                    {props?.did && <button className={s.button + (loading ? ' ' + s.buttonHidden : '')}><a className={s.link} onClick={()=>onCall(props.did.tracker)} href={`tel:${determineNumber(props.did.tracker)}`}>{determineHumanNumber(props.did.tracker)}</a></button>}
                    </div>
                </div>
            </div>
        )
    }else{
        return(
        <div className={s.containerUser}>
            <div className={s.messageContainerUser}>
                <p>{props.text}</p>
            </div>
            <div className={s.avatarContainer}>
                <img className={s.avatarImage} src={config.chat.userAvatar} />
            </div>
        </div>
        )
    }

}

export default ChatMessage;