import React from "react";
import { config } from "../../config/main";
import s from './style.module.scss';
import HeaderSpanish from './index_sp';

const Header = () => {
    return config.language === 'es' ? (
        <HeaderSpanish />
    ) : (
        <div className={s.container}>
            <h3>Offices Close Today at 6:00 PM EST</h3>
            <h1>{config.header.headline}</h1>
            <div className={s.online}>
                <p className={s.pulsatingCircle}></p><p className={s.botName}> {config.chat.botName} is Online.</p>
            </div>
        </div>
    );
}

export default Header;