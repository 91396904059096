import {
    MainConfig
} from "../../types";

import { redirect } from "../../utils";

export const config : MainConfig = {
    language: "en",
    chat: {
        botName: "Emily",
        botAvatar: "./img/avatar.webp",
        userAvatar: "./img/user.png",
        typingDelay: 500,
        sections: [{
                page_name: "find_out_if_you_qualify",
                messages: [{
                        text: `Hi ${String.fromCodePoint(128079)}`,
                        delay: 1000,
                    },
                    {
                        text: "I’m Emily from Health Benefits, your virtual AI assistant",
                        delay: 1000,
                    },
                    {
                        text: `Curious if your household qualifies for up to $1,400 Monthly in Premium Tax Credits? ${String.fromCodePoint(128512)}`,
                        delay: 1000,
                    },
                    {
                        text: null,
                        delay: 0,
                        selections: [{
                            questionKey: "opt_in",
                            title: "Yes",
                            value: true,
                            nextSectionIndex: 1
                        }]
                    }
                ],
            },
            {
                page_name: "income_less_than_60k",
                messages: [{
                        text: "Okay, let me ask you a couple quick questions.",
                        delay: 1000
                    },
                    {
                        text: "Do you make less than $60,000/year? Tap Yes or No.",
                        delay: 1000
                    },
                    {
                        delay: 0,
                        selections: [{
                                questionKey: "income_less_than_60k",
                                title: "Yes",
                                value: true,
                                nextSectionIndex: 2
                            },
                            {
                                questionKey: "income_less_than_60k",
                                title: "No",
                                value: false,
                                nextSectionIndex: 2
                            }
                        ]
                    }
                ],
    
            },
            {
                page_name: "over_or_under_65",
                messages: [{
                        text: "Are you over or under 65?",
                        delay: 1000
                    },
                    {
                        delay: 0,
                        selections: [{
                                questionKey: "age",
                                title: "Over 65",
                                value: false,
                                nextSectionIndex: 4
                            },
                            {
                                questionKey: "age",
                                title: "Under 65",
                                value: "Medicaid",
                                nextSectionIndex: 3
                            },
                        ]
                    }
                ],
            },
            {
                page_name: "medicare_or_medicaid",
                messages: [{
                        text: "Are you on medicaid or medicare?",
                        delay: 1000
                    },
                    {
                        delay: 0,
                        selections: [{
                                questionKey: "medicaid_or_medicare",
                                title: "Medicare",
                                value: "Medicare",
                                nextSectionIndex: 4
                            },{
                                questionKey: "medicaid_or_medicare",
                                title: "Medicaid",
                                value: "Medicaid",
                                nextSectionIndex: 9
                            },{
                                questionKey: "medicaid_or_medicare",
                                title: "No",
                                value: false,
                                nextSectionIndex: 5
                            },
                   
                        ]
                    }
                ],
            },
            {
                page_name: "medicare_a_b",
                messages: [{
                        text: "Do you have Medicare Parts A and B?",
                        delay: 1000
                    },
                    {
                        delay: 0,
                        selections: [{
                                questionKey: "medicare_a_b",
                                title: "Yes",
                                value: "Yes",
                                nextSectionIndex: 6
                            },{
                                questionKey: "medicare_a_b",
                                title: "No",
                                value: false,
                                nextSectionIndex: 7
                            },
                   
                        ]
                    }
                ],
            },
            {
                page_name: "td_aca",
                messages: [
                    {
                        text: `${String.fromCodePoint(127881)} Congratulations! ${String.fromCodePoint(127873)}`,
                        delay: 1000,
                    },
                    {
                        text: "Your Household May Qualify For a $0 Cost Health Plan via $1,400 Monthly in Premium Credits and a Potential Prepaid Card.",
                        delay: 1000
                    },
                    {
                        text: "This credit can help cover your medical expenses and the savings provide relief for rent, bills, groceries, and more. Tap Below to Call and Activate Your Benefits. It only takes 2 minutes! REMEMBER, you’ve been Pre-Qualified—within the first 90 seconds of the call, the agent will confirm your information to finalize your qualification.",
                        delay: 1000
                    },
                    
                    {
                        delay: 1000,
                        did: {
                            tracker: 'trackdrive'
                        }
                    },
                    {
                        text: "TTY: 711",
                        delay: 500
                    },
                    {
                        text: "Office Hours: M-F 9am-6pm ET",
                        delay: 500
                    }
                ],
            },
            {
                page_name: "retreaver_medi",
                messages: [
                    {
                        text: `Great News! ${String.fromCodePoint(127881)}`,
                        delay: 1000,
                    },
                    {
                        text: "Although you aren’t qualified for a a $0 Cost Health Plan via $1,400 Monthly in Premium Credits and a Potential Prepaid Card through ACA Insurance, you may be eligible for a Medicare Advantage Plan with additional plan benefits if you have a Special Enrollment Period available to you. Like recently moved, or have other qualifying events.",
                        delay: 1000
                    },
                    {
                        text: "Call the number below to be connected with a licensed sales agent to check your eligibility to enroll and learn more about these plan options:",
                        delay: 1000
                    },
                    {
                        delay: 0,
                        did: {
                            tracker: "retreaver"
                        }
                    },
                    {
                        text: "TTY: 711",
                        delay: 500
                    },
                    {
                        text: "Office Hours: M-F 9am-6pm ET",
                        delay: 500
                    },
                ],
            },
            {
                page_name: "acp",
                messages: [
                    {
                        text: `Great News! ${String.fromCodePoint(127881)}`,
                        delay: 1000,
                    },
                    {
                        text: "Although you aren’t qualified a $0 Cost Health Plan via $1,400 Monthly in Premium Credits and a Potential Prepaid Card, agencies are now offering free cellular plans and phones.",
                        delay: 1000
                    },
                    {
                        text: "Click the link below to secure your free phone and cellular data plan now!",
                        delay: 1000
                    },
                    {
                        delay: 0,
                        selections: [{
                            title: "Get My Free Phone!",
                            onClick: () => redirect('https://273externalid.mvnocloudsolutions.com/?AccountID=20')
                        }
                    ]
                    }
                ],
            },
            {
                page_name: "DQ",
                messages: [
                    {
                        text: "Sorry, but the questions you answered have helped us determine that we are not able to help you at this time.",
                        delay: 1500
                    },
                    {
                        text: `Have a nice day! ${String.fromCodePoint(128512)}`,
                        delay: 1500
                    },
                ],
            },
            {
                page_name: "credit_repair",
                messages: [
                    {
                        text: `Great News! ${String.fromCodePoint(127881)}`,
                        delay: 1000,
                    },
                    {
                        text: "Although you don't qualify a $0 Cost Health Plan via $1,400 Monthly in Premium Credits and a Potential Prepaid Card, this new program can allow you increase your credit score quickly.",
                        delay: 1000
                    },
                    {
                        delay: 0,
                        selections: [{
                            title: "Increase my credit score!",
                            onClick: () => redirect('https://www.frebahlem.com/BGXRTCHP/HT1C69P/')
                        }
                    ]
                    }
                ],
            },
        ]
    },
    header: {
        headline: "Eligible Americans Can Receive Up to $1,400 Monthly in Premium Health Credits to Offset Health Care Costs and May Qualify for a Prepaid Spending Card for Qualified Medical Expenses."
    },
    disclosures: {
        "paragraphs": [
            "MULTI-PLAN_5imgij6_2024_C MULTI-PLAN_5imgij6_2025_C",            
            "Participating sales agencies represent Medicare Advantage HMO, PPO and PFFS organizations and stand-alone PDP prescription drug plans that are contracted with Medicare. Enrollment depends on the plan’s contract renewal. Enrollment in the described plan type may be limited to certain times of the year unless you qualify for a Special Enrollment Period.",
            "This website is not affiliated with the Affordable Cares Act program or any other government entity. The information provided on this website is for informational purposes only. It is not intended to be, nor does it constitute any kind of financial advice. Please seek advice from a qualified professional prior to making any financial decisions based on the information provided. This website acts as an independent digital media & advertising publisher. This webpage is formatted as an advertorial. An advertorial is an advertisement that is written in an editorial news format. PLEASE BE AWARE THAT THIS IS AN ADVERTISEMENT AND NOT AN ACTUAL NEWS ARTICLE, BLOG, OR CONSUMER PROTECTION UPDATE. This website MAY RECEIVE PAID COMPENSATION FOR CLICKS OR SALES PRODUCED FROM THE CONTENT FOUND ON THIS WEBPAGE. Any information, discounts, or price quotations listed may not be applicable in your location or if certain requirements are not met. Additionally, our advertisers may have additional qualification requirements.",
            "DISCLOSURE REGARDING AFFORDABLE CARE ACT HEALTH PLANS: The average monthly advanced premium tax credit across all enrollees was $527/month in 2023, with an average monthly premium of $123.69. Results vary based on a number of factors, including age, income, family size, health history, cost of available insurance coverage, where you live, and more. You are not eligible for the premium tax credit for coverage purchased outside the marketplace.",
            "Our goal is to provide exceptional service. One of our agents may reach out to you to discuss your order, ask for feedback, and/or see if you need any assistance with your products, services, or plans, at the phone number you provided regardless of your do-not-call list status. You may opt-out of further contact at any time by simply telling our customer service team that you would no longer like to be contacted. In the event that our team is unable to reach you by phone, they may send you a text message letting you know that we called. Both our text messages and phone calls may be sent or connected utilizing automated software. Carrier charges may apply. You may opt-out of any future contact via text message by replying anytime with \"STOP\"."
        ],
        "information_links": [
            {"title": "ACHI 2022", "href": "https://achi.net/newsroom/house-passes-bill-extending-aca-subsidies-through-2025-ensuring-continued-affordable-healthcare-insurance-for-millions/?"},
            {"title": "KFF 2022", "href": "https://www.kff.org/faqs/faqs-health-insurance-marketplace-and-the-aca/how-do-the-premium-tax-credits-work/"},
            {"title": "IRS 2022", "href": "https://www.irs.gov/affordable-care-act/individuals-and-families/the-premium-tax-credit-the-basics"},
            {"title": "Health Reform Basics 2022", "href": "https://www.healthreformbeyondthebasics.org/premium-tax-credits-answers-to-frequently-asked-questions/"},
            {"title": "CMS.gov", "href": "https://cms.gov"}
        ],
        "terms_links": [
            {"title": "Privacy Policy", "href": "https://chat.healthbenefitsnow.org/privacy_policy/index.html" },
            {"title": "Terms and Conditions", "href": "https://chat.healthbenefitsnow.org/terms_of_service/index.html"}
        ]
    },
    numberPools: {
        retreaver: {
            campaign_key: "bd376b8fa8c3c1efae7dfc8e19bb179e"
        },
        retreaverACA: {
            campaign_key: "249731b64ba9c84240b7934560eba899"
        },
        trackdrive: {
            offer_token: "754a9936801e8ff256925b58a748521e",
            default_number: "+18449340565",
            default_human_number: "(844) 934 0565"
        }
    }
}