import React from 'react';
import Header from './components/Header';
import Chat from './components/Chat';
import s from './style.module.scss'
import Disclosures from './components/Disclosures';

function App() {
  return (
    <div>
      <div className={s.container}>
        <div className={s.contentContainer}>
          <Header />
          <Chat />
        </div>
      </div>
      <Disclosures />
    </div>
  );
}

export default App;
